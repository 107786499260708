.wrapper {
  background-image: url('https://res.cloudinary.com/gwatco/image/upload/w_800,dpr_auto/new-website/static/homepage/banner.jpg');
  background-size: cover;
  background-position: center;
}
@media (min-width: 640px) {
  .wrapper {
    background-image: url('https://res.cloudinary.com/gwatco/image/upload/t_banner-xl,dpr_auto/new-website/static/homepage/banner-test2.webp');
    background-size: cover;
    background-position: bottom;
  }
}

/*@screen lg {*/
/*  background-position: 0 -10px;*/
/*}*/

.panel {
  box-shadow: 0 0 25px 2px rgba(0 0 0 / 20%);
}

.activeTab {
  @apply relative text-teal-900;
}
.activeTab::after {
  content: '';
  border-bottom: 2px solid #0083a0;
  position: absolute;
  bottom: -0.1rem;
  left: 0;
  right: 0;
  margin: 0 0.2rem;
  width: 100%;
}
