.reviews-section_stroke__1TSQo{
    position: relative;
}

.reviews-section_stroke__1TSQo::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: -5px;
    width: 128%;
    page-break-after: always;
    height: 100%;
    background: url(https://gwatco-res.cloudinary.com/image/upload/v1680056080/new-website/static/homepage/stroke.svg) no-repeat bottom;
    margin: auto 0;
}
.search-panel_wrapper__OaGKn {
  background-image: url('https://res.cloudinary.com/gwatco/image/upload/w_800,dpr_auto/new-website/static/homepage/banner.jpg');
  background-size: cover;
  background-position: center;
}
@media (min-width: 640px) {
  .search-panel_wrapper__OaGKn {
    background-image: url('https://res.cloudinary.com/gwatco/image/upload/t_banner-xl,dpr_auto/new-website/static/homepage/banner-test2.webp');
    background-size: cover;
    background-position: bottom;
  }
}

/*@screen lg {*/
/*  background-position: 0 -10px;*/
/*}*/

.search-panel_panel__b0QoH {
  box-shadow: 0 0 25px 2px rgba(0 0 0 / 20%);
}

.search-panel_activeTab__RRORv {
  position: relative;
  --tw-text-opacity: 1;
  color: rgba(0, 131, 160, var(--tw-text-opacity));
}
.search-panel_activeTab__RRORv::after {
  content: '';
  border-bottom: 2px solid #0083a0;
  position: absolute;
  bottom: -0.1rem;
  left: 0;
  right: 0;
  margin: 0 0.2rem;
  width: 100%;
}

.search-panel_searchPanel__ULLqr p {
    text-align: left;
    font-size: 0.875rem;
    line-height: 0.875rem;
    line-height: 1rem
}

.above-the-fold_divisionInfo__6c4ht h1 {
    margin-bottom: 1.25rem;
    font-size: 1.625rem;
    line-height: 1.625rem;
    font-weight: 700;
    line-height: 2rem;
    --tw-text-opacity: 1;
    color: rgba(23, 24, 26, var(--tw-text-opacity))
}
.above-the-fold_divisionInfo__6c4ht h4 {
    margin-top: 2.5rem;
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    --tw-text-opacity: 1;
    color: rgba(23, 24, 26, var(--tw-text-opacity))
}

.above-the-fold_divisionInfo__6c4ht p {
    font-size: 1.125rem;
    line-height: 1.125rem;
    line-height: 1.5rem
}

.above-the-fold_divisionInfo__6c4ht ul {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 1.25rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 0px;
    padding-bottom: 0px
}

@media (min-width: 768px) {
    .above-the-fold_divisionInfo__6c4ht ul {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
}

@media (min-width: 1024px) {
    .above-the-fold_divisionInfo__6c4ht ul {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }
}
.above-the-fold_divisionInfo__6c4ht ul li {
    display: flex;
    align-items: baseline;
    font-size: 1rem;
    line-height: 1rem;
    --tw-text-opacity: 1;
    color: rgba(87, 91, 99, var(--tw-text-opacity))
}

.above-the-fold_divisionInfo__6c4ht ul li img {
    margin-right: 0.25rem;
    height: 2.25rem;
    width: 2.25rem;
    --tw-translate-y: 0.75rem;
    transform: var(--tw-transform);
    transform: var(--tw-transform)
}
.above-the-fold_divisionInfo__6c4ht ul li strong {
    margin-right: 0.25rem
}

